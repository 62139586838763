import {Fitch} from '@fiizy/fitch';
import tracking, {getNewChannelHash} from '@fiizy/tracking';
import clientData from "./clientData";
import {authToken, isUserAuth} from "./auth";

const apiService = new Fitch({
    baseURL: `${app.config.apiUrl}/api/v2`,
    transformRequest: [addClientData, authHeader]
})

function getChannelHash() {
    const originalChannelHash = tracking.getSavedChannel()
    const channelHashes = {
        seo: app.config.seoChannelHash,
        default: app.config.channelHash,
        saved: originalChannelHash,
        calculator: app.config.calculatorChannelHash
    }
    const urlParams = new URLSearchParams(window.location.search)

    return getNewChannelHash({
        urlParams,
        referrer: document.referrer,
        channelHashes,
        forceChannel: app.config.forceChannel === 'true'
    })
}

function authHeader(request) {
    if (authToken) {
        request.headers = {...request.headers, Authorization: `Bearer ${authToken}`}
    }
    return request
}

function addClientData(request) {
    if (request.body) {
        request.body = { ...request.body,  ...clientData() }
    }

    return request
}

async function findProcessRequest() {
    const token = await isUserAuth()
    if (!token) return

    const resourcePath = `/processes`

    let body = {
        attribution: {
            channel_hash: getChannelHash()
        }
    }

    return apiService.post(resourcePath, body).catch(() => {
        return undefined
    })
}

function patchNotificationQueueAction(request) {
    const resourcePath = `/notifications/queue/${request.queue_hash}`

    return apiService.patch(resourcePath, request).catch(() => {
        return undefined
    })
}

function generateModalContent(notification) {
    const notificationModal = document.createElement('div')
    notificationModal.innerHTML = `
        <div class="modal-mask ${notification.hash}">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header collapsed">
                        <slot name="header" />
                    </div>
                    <div class="modal-body">
                        <slot name="body" />
                    </div>
                </div>
            </div>
        </div>`

    document.body.appendChild(notificationModal)


    notificationModal.querySelector('slot[name="body"]').innerHTML = notification.content

    const header = notificationModal.querySelector('slot[name="header"]')
    header.innerHTML = notification.subject

    const lottieUrl = header.querySelector('img[data-animation]').dataset.animation
    header.querySelector('img').parentElement.innerHTML = `<lottie-player src="${lottieUrl}" background="transparent" speed="1" style="width: 188px; height: 100px;" loop autoplay></lottie-player>`

    const modalWrapper = notificationModal.querySelector('.modal-mask')
    modalWrapper.classList.add('scale-in')
    // modalWrapper.style.display = 'table'

    notificationModal.addEventListener('click', () => {
        modalWrapper.classList.remove('scale-in')
        modalWrapper.classList.add('scale-out')
        modalWrapper.style.display = 'none'
    })
}

function getNotifications() {
    findProcessRequest().then((data) => {
        if (!data || !Array.isArray(data.fields)) return

        const notification = data.fields.find((field) => field.field_type === 'flash_notification')
        if (notification && notification.properties && notification.properties.notifications) {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.async = false
            script.src = "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
            script.onerror = () => reject(new Error('Could not load script: ' + app.config.calcPath))
            document.head.appendChild(script)

            notification.properties.notifications.forEach((notification) => {
                generateModalContent(notification)

                patchNotificationQueueAction({
                    queue_hash: notification.hash,
                    form: {
                        status: 'delivered'
                    }
                })
            })
        }

    })
}


document.addEventListener('DOMContentLoaded', () => {
    getNotifications()
});
